const colors = {
  white: {
    A700: "#ffffff",
  },
  black: {
    "900": "#000000",
  },
  gray: {
    "100": "#f2f5fc",
    "400": "#bdbdbd",
    "700": "#5e5e5f",
    "900": "#242425",
  },
  purple: {
    "600": "#874b97",
  },
  light_blue: {
    "400": "#25a9e0",
  },
  green: {
    "400": "#7aba62",
    "800": "#248f2f",
  },
  yellow: {
    "300": "#eeeb77",
  },
  red: {
    "300": "#d28856",
    "600": "#d9503e",
    A700: "#d31313",
  },
  pink: {
    "900": "#7a302e",
  },
  deep_orange: {
    "600": "#f36216",
  },
};
export default colors;
