import React from "react";
import { Marker } from 'react-leaflet';
import { icon } from "leaflet";
import { appGood, appModerate, appUnhealthy, appUnhealthyforsensitivegroups, staticGood, staticHazardous, staticModerate, staticUnhealthy, staticUnhealthyforsensitivegroups, staticVeryUnhealthy, wearableGood, wearableHazardous, wearableModerate, wearableUnhealthy, wearableUnhealthyforsensitivegroups, wearableVeryUnhealthy, whiteApp } from "assets";


// export const staticmoderate = divIcon({
//     className: 'custom-marker-icon',
//     html: staticModerate,
// });

export const staticmoderate = icon({
    iconUrl: staticModerate,
    iconSize: [35, 35],
});

export const staticgood = icon({
    iconUrl: staticGood,
    iconSize: [35, 35],
});

export const staticunhealthyforsensitivegroups = icon({
    iconUrl: staticUnhealthyforsensitivegroups,
    iconSize: [35, 35],
});
export const staticunhealthy = icon({
    iconUrl: staticUnhealthy,
    iconSize: [35, 35],
});
export const staticveryunhealthy = icon({
    iconUrl: staticVeryUnhealthy,
    iconSize: [35, 35],
});
export const statichazardous = icon({
    iconUrl: staticHazardous,
    iconSize: [35, 35],
});

export const appmoderate = icon({
    iconUrl: appModerate,
    iconSize: [35, 35],
});
export const appgood = icon({
    iconUrl: appGood,
    iconSize: [35, 35],
});
export const appunhealthyforsensitivegroups = icon({
    iconUrl: appUnhealthyforsensitivegroups,
    iconSize: [35, 35],
});
export const appunhealthy = icon({
    iconUrl: appUnhealthy,
    iconSize: [35, 35],
});

export const wearablemoderate = icon({
    iconUrl: wearableModerate,
    iconSize: [35, 35],
});

export const wearablegood = icon({
    iconUrl: wearableGood,
    iconSize: [35, 35],
});

export const wearableunhealthyforsensitivegroups = icon({
    iconUrl: wearableUnhealthyforsensitivegroups,
    iconSize: [35, 35],
});
export const wearableunhealthy = icon({
    iconUrl: wearableUnhealthy,
    iconSize: [35, 35],
});
export const wearableveryunhealthy = icon({
    iconUrl: wearableVeryUnhealthy,
    iconSize: [35, 35],
});
export const wearablehazardous = icon({
    iconUrl: wearableHazardous,
    iconSize: [35, 35],
});

export const appwhite = icon({
    iconUrl: whiteApp,
    iconSize: [35, 35],
});





export function CustomMarkersWithIcon({ category, colorCode, position, onClick, measurement, }) {
    const handleClick = (e) => {
        onClick(e);
      };
    switch (category) {
        case 'static':
            switch (colorCode) {
                case 'moderate':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={staticmoderate} />
                    );
                case 'good':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={staticgood} />
                    );
                case 'unhealthyforsensitivegroups':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={staticunhealthyforsensitivegroups} />
                    );
                case 'unhealthy':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={staticunhealthy} />
                    );
                case 'veryunhealthy':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={staticveryunhealthy} />
                    );
                case 'hazardous':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={statichazardous} />
                    );
                default:
                    return <></>;
            }
        case 'app':
            switch (colorCode) {
                case 'moderate':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={appmoderate} />
                    );
                case 'good':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={appgood} />
                    );
                case 'unhealthyForSensitiveGroups':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={appunhealthyforsensitivegroups} />
                    );
                case 'unhealthy':
                    return (
                        <Marker position={position} eventHandlers={{ click: handleClick }} icon={appunhealthy} />
                    );
                default:
                    return <Marker position={position} eventHandlers={{ click: handleClick }} icon={appwhite} />;
            }
        default:
            return <></>
    }
}

// return (
//     <div style={{ borderRadius: "50%" }}>
//         <Marker position={position} icon={customIcon} eventHandlers={{ click: onClick }} />
//     </div>
// )