import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MapContainer, TileLayer, Marker, ZoomControl, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { LatLngTuple } from 'leaflet';
import { setisOpen, toggleSidebar } from './reducer/reducer';
import './App.css';
import { RootState } from './store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Sidebar } from 'components/sidebar';
import { Button, Flex, Heading } from '@chakra-ui/react';
import Visualization from 'components/Visualization';
import { AirQuality, staticDefaultdata } from 'reducer/state';
import { CustomMarkersWithIcon, staticgood, statichazardous, staticmoderate, staticunhealthy, staticunhealthyforsensitivegroups, staticveryunhealthy, wearablegood, wearablehazardous, wearablemoderate, wearableunhealthy, wearableunhealthyforsensitivegroups, wearableveryunhealthy } from 'assets/icons/icons';
import cityWithLocation from './data/lagos-accra_updated_loc.json'
import { mapLegend } from 'assets';



function App() {
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const appData = useSelector((state: RootState) => state.app.appEntity);
  const staticData = useSelector((state: RootState) => state.app.staticEntity);
  const wearableData = useSelector((state: RootState) => state.app.wearableEntity);
  const isOpen = useSelector((state: RootState) => state.app.isOpen);
  const isSidebarCollapse = useSelector((state: RootState) => state.app.isSidebarCollapse);
  const city = useSelector((state: RootState) => state.app.city);
  const dispatch = useDispatch();
  const position: LatLngTuple = [6.4549, 3.4246]; // Africa centroid. Used for initial map view


  const toggleSidebarHandler = () => {
    dispatch(toggleSidebar());
  };

  const handleClick = (data, cat) => {
    // e.preventDefault();
    setOpen(true);
    setSelectedData(data);
    dispatch(setisOpen(false));
    console.log("data", data);
  };
  console.log("setOpen", open);

  const handleClose = () => {
    console.log("handleClose");
    setOpen(false); // Close DataDisplay component
    setSelectedData(null); // Clear selected data
    dispatch(setisOpen(true));
  };



  function filterDataByDeviceIDs(data, deviceIDs) {
    if (data && deviceIDs) {
      return data.filter(item => deviceIDs.includes(item[0]));
    }
    return null
  }

  const [filteredData, setFilteredData] = useState<any>(null);

  // Assuming `staticData.data` contains the data to be filtered
  useEffect(() => {
    const filtered = filterDataByDeviceIDs(staticData?.data, Object.keys(cityWithLocation ? cityWithLocation : {}));
    setFilteredData(filtered);
  }, [city, staticData?.data]);
  console.log("static data", staticData?.data);
  console.log("app data", appData?.data);
  console.log("wearable data", wearableData?.data);
  console.log("filtered data", filteredData);
  console.log("filtered cityWithLocation", cityWithLocation);

  const staticIconMap = {
    moderate: staticmoderate,
    good: staticgood,
    unhealthy: staticunhealthy,
    veryunhealthy: staticveryunhealthy,
    unhealthyforsensitivegroups: staticunhealthyforsensitivegroups,
    hazardous: statichazardous,
  };

  const wearableIconMap = {
    moderate: wearablemoderate,
    good: wearablegood,
    unhealthy: wearableunhealthy,
    veryunhealthy: wearableveryunhealthy,
    unhealthyforsensitivegroups: wearableunhealthyforsensitivegroups,
    hazardous: wearablehazardous,
  };

  const lowerCased = (item) => {
    return item.replace(/\s+/g, '').toLowerCase();
  }

  const [showComponent, setShowComponent] = useState(false);

  const handleCloseLegend = () => {
    console.log("handleCloseLegend")
    setShowComponent(!showComponent);
  };

  return (
    <div className='App' style={{ position: 'relative' }}>
      {open ?
        <div className="visualization" style={{ position: 'absolute', zIndex: 1000, top: "6px", scrollbarWidth: 'none' }}>
          <Visualization data={selectedData} handleClose={handleClose} />
        </div>
        : null
      }
      <div className='sidebar-container' style={{ height: !isSidebarCollapse ? "59px" : "100vh", zIndex: 900 }}>
        <div style={{ display: isOpen ? "block" : "none" }}>
          {/* {isOpen ? */}
          <Sidebar />
          {/* : null
          } */}
        </div>
        <div className="sidebar-toggle" style={{ alignContent: 'center', borderRadius: "5px", position: 'absolute', height: '40px', width: '40px', zIndex: 1000, background: '#25a9e0', top: '10px', left: isOpen ? 'calc(316px + 10px)' : "10px" }}>
          {isOpen ?
            <FontAwesomeIcon icon={faArrowLeft} onClick={toggleSidebarHandler} size='lg' color="#fff" style={{ marginLeft: '11px' }} />
            :
            <FontAwesomeIcon icon={faArrowRight} onClick={toggleSidebarHandler} size='lg' color="#fff" style={{ marginLeft: '11px' }} />
          }
        </div>
      </div>
      <div className='map-container' style={{ position: 'absolute', zIndex: 1 }}>
        <MapContainer zoomAnimation center={position} zoomAnimationThreshold={5} zoom={11} style={{ height: '100vh', width: '100vw' }} zoomControl={false}>
          <ZoomControl position="topright" />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {appData?.data && appData.data.map((item, index) => (
            <CustomMarkersWithIcon key={index} category="app" colorCode={AirQuality[item[4]]} position={[Number(item[8]), Number(item[9])]} onClick={() => handleClick(item, "app")} measurement={null} />
          ))}
          {filteredData && staticData && filteredData.map((item, index) => (
            <Marker
              key={index}
              position={[cityWithLocation[item[0]].latitude, cityWithLocation[item[0]].longitude]}
              icon={staticIconMap[lowerCased(item[1])] || staticgood}
            >
              <Popup key={index}>
                <Flex key={index} gap="17px" flexDirection="column" alignItems="start">
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">Lat: </Heading>
                    {staticDefaultdata[item[0]][0]}
                  </Flex>
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">Lon: </Heading>
                    {staticDefaultdata[item[0]][1]}
                  </Flex>
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">PM(2.5) </Heading>
                    <>
                      {item[2]} / {item[1]}
                    </>
                  </Flex>
                </Flex>
              </Popup>
            </Marker>
          ))}
          {wearableData?.data && city && wearableData?.data.map((item, index) => (
            <Marker
              key={`wearable-${index}`}  // Unique key for each marker
              position={[item[3], item[4]]}
              icon={wearableIconMap[lowerCased(item[2])] || wearablemoderate}
            >
              <Popup key={index}>
                <Flex key={index} gap="17px" flexDirection="column" alignItems="start">
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">Lat: </Heading>
                    {item[3]}
                  </Flex>
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">Lon: </Heading>
                    {item[4]}
                  </Flex>
                  <Flex gap="8px" alignSelf="stretch" flexDirection="row" justifyContent="space-between">
                    <Heading as="h3">PM(2.5) </Heading>
                    <>
                      {item[1]} / {item[2]}
                    </>
                  </Flex>
                </Flex>
              </Popup>
            </Marker>
          ))}
          {/* <div onClick={handleCloseLegend} style={{ position: "fixed", cursor: "pointer", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", zIndex: 2000, bottom: "35px", right: "35px", height: "40px", width: "40px", borderRadius: "50%", }}>
            <img
              src={mapLegend}
              alt="Legend"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "50%"
              }}
            />
          </div>
          {showComponent &&
            <div style={{
              position: "fixed",
              bottom: "100px",
              right: "35px",
              padding: "10px",
              background: "#fff",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)"
            }}>
              This is the component that appears on click!
            </div>
          } */}
        </MapContainer>
      </div>
    </div>
  );
}

export default App;