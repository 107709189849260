import { LatLngTuple } from "leaflet";
import { format, parse } from 'date-fns';


export interface InitialState {
    isOpen?: boolean;
    isSidebarCollapse?: boolean;
    isLoading?: boolean;
    error?: any;
    appEntity?: any | null;
    wearableEntity?: any | null;
    staticEntity?: any | null;
    city?: any | null;
    filteredData?: Data | null;
}

export interface Data {
    date: {
        start: string,
        end: string
    }
    airQuality: {
        moderate: boolean,
        good: boolean,
        unhealthyForSensitiveGroups: boolean,
        unhealthy: boolean,
        veryUnhealthy: boolean,
        hazardous: boolean
    },
    city: {
        lagos: boolean,
        accra: boolean
    },
    mediaType: {
        image: boolean,
        video: boolean,
        audio: boolean,
        text: boolean
    },
    dataSource: {
        app: boolean,
        wearable: boolean,
        staticSensor: boolean
    },
    environment: {
        airQuality: {
            goodAirQuality: boolean,
            badAirQuality: boolean
        },
        walkability: {
            goodWalkability: boolean,
            badWalkability: boolean
        },
        heat: {
            goodHeat: boolean,
            badHeat: boolean
        },
        aesthetics: {
            goodAesthetics: boolean,
            badAesthetics: boolean
        },
        greenspace: {
            goodGreenSpace: boolean,
            badGreenSpace: boolean
        },
        other: {
            goodOther: boolean,
            badOther: boolean
        },
        label: {
            boolNotApplicable: boolean,
            boolStartRun: boolean,
            boolEndRun: boolean,
            boolGood: boolean,
            boolBad: boolean
        }
    },
    risks: {
        riskObstructions: boolean,
        riskInjury: boolean,
        riskSafetySecurity: boolean,
        riskRestrictedAccess: boolean,
        riskNoise: boolean,
        riskDust: boolean,
        riskTraffic: boolean,
        riskBurning: boolean,
        riskSmell: boolean,
        riskOther: boolean
    },
    benefits: {
        benefitLighting: boolean,
        benefitBarrierCarDedicatedPath: boolean,
        benefitInclusive: boolean,
        benefitTrees: boolean,
        benefitNiceView: boolean,
        benefitOther: boolean,
        benefitBlueSpace: boolean
    }


}


export const defaultNewData: Data = {
    date: {
        start: format("2024-04-14", 'yyyy-MM-dd'),
        end: ""
    },
    airQuality: {
        moderate: true,
        good: true,
        unhealthyForSensitiveGroups: true,
        unhealthy: true,
        veryUnhealthy: true,
        hazardous: true
    },
    city: {
        lagos: true,
        accra: true
    },
    mediaType: {
        image: true,
        video: true,
        audio: true,
        text: true
    },
    dataSource: {
        app: true,
        wearable: true,
        staticSensor: true
    },
    environment: {
        airQuality: {
            goodAirQuality: true,
            badAirQuality: true
        },
        walkability: {
            goodWalkability: true,
            badWalkability: true
        },
        heat: {
            goodHeat: true,
            badHeat: true
        },
        aesthetics: {
            goodAesthetics: true,
            badAesthetics: true
        },
        greenspace: {
            goodGreenSpace: true,
            badGreenSpace: true
        },
        other: {
            goodOther: true,
            badOther: true
        },
        label: {
            boolNotApplicable: true,
            boolStartRun: true,
            boolEndRun: true,
            boolGood: true,
            boolBad: true
        }
    },
    risks: {
        riskObstructions: true,
        riskInjury: true,
        riskSafetySecurity: true,
        riskRestrictedAccess: true,
        riskNoise: true,
        riskDust: true,
        riskTraffic: true,
        riskBurning: true,
        riskSmell: true,
        riskOther: true
    },
    benefits: {
        benefitLighting: true,
        benefitBarrierCarDedicatedPath: true,
        benefitInclusive: true,
        benefitTrees: true,
        benefitNiceView: true,
        benefitOther: true,
        benefitBlueSpace: true,
    }
}

// export const defaultData: Data = {
//     airQuality: {
//         moderate: false,
//         good: false,
//         unhealthyForSensitiveGroups: false,
//         unhealthy: false,
//         veryUnhealthy: false,
//         hazardous: false
//     },
//     city: {
//         lagos: false,
//         accra: false
//     },
//     mediaType: {
//         image: false,
//         video: false,
//         audio: false,
//         text: false
//     },
//     dataSource: {
//         app: false,
//         wearable: false,
//         staticSensor: false
//     }
// }


export const staticDefaultdata: Record<string, LatLngTuple> = {
    'airqo_g5217': [6.59441, 3.38328],
    'airqo_g5229': [6.55216, 3.38807],
    'airqo_g5200': [6.44205, 3.37849],
    'airqo-g5142': [6.44825, 3.40617],
    'airqo-g5153': [6.53257, 3.39936],
    'airqo-g5176': [6.54474, 3.34624],
    'airqo_g5256': [6.48285, 3.35789],
    'aq_g5_94': [6.5954, 3.29185],
    'airqo-g5194': [6.475, 3.20151],
    'airqo_g5254': [6.6716, 3.53412],
    'airqo_g5234': [6.5903, 3.34221],
    'airqo_g5245': [6.51727, 3.39837],
    'airqo_g5249': [6.56148, 3.25106],
    'airqo_g5237': [6.61284, 3.36053],
    'airqo-g5178': [6.42079, 3.40798],
    'airqo_g5240': [6.56134, 3.25074],
    'airqo_g5196': [6.56909, 3.36731],
    'airqo_g5241': [6.52724, 3.35575],
    'airqo-g5167': [6.48994, 3.38619],
    'airqo_g5226': [6.51767, 3.38542],
    'airqo_g5213': [6.64789, 3.31845],
    'aq_g0_15': [5.580642, -0.170724],
    'airqo_g5205': [5.65147, -0.185644],
    'airqo_g5294': [5.65147, -0.185644],
    'airqo_g5295': [5.65147, -0.185644],
    'airqo_g5313': [5.65147, -0.185644],
    'airqo-g5171': [5.65147, -0.185644],
    'airqo_g5197': [5.65147, -0.185644],
    'airqo_g5212': [5.65147, -0.185644],
    'airqo_g5219': [5.54916, -0.26057],
    'airqo_g5236': [5.59345, -0.19509],
    'airqo_g5233': [5.55037, -0.20562],
    'airqo_g5288': [5.57832, -0.17078],
    'airqo_g5290': [5.57832, -0.17078],
    'airqo_g5277': [5.55154, -0.20008],
    'airqo_g5283': [5.55154, -0.20008],
    'airqo_g5230': [5.54551, -0.21771],
    'airqo_g5239': [5.61252, -0.22955],
    'airqo_g5231': [5.58593, -0.20106]
};

export interface AppSensorsData {
    image: string;
    video: string;
    text: string;
    audio: string;
    description: string;
    latitude: string;
    longitude: string;
}

export const AirQuality = {
    "Source of clean air": "good",
    "Source of polluted air": "unhealthy",
    "Other health benefit": "moderate",
    "Other health risk": "unhealthyForSensitiveGroups",
}

export const CategoryToColor = {
    "Good": "good",
    "Very Unhealthy": "unhealthy",
    "Other health benefit": "moderate",
    "Other health risk": "unhealthyForSensitiveGroups",
}

const data = {
    'aqi_color_name': ['Yellow', 'Green', 'Orange', 'Red', 'Purple', 'Unknown'],
    'aqi_category': ['Moderate', 'Good', 'Unhealthy for Sensitive Groups', 'Unhealthy', 'Very Unhealthy', 'Hazardous'],
    'aqi_color': ['#ffff00', '#00e400', '#ff7e00', '#ff0000', '#8639c0', '#81202e']
}