import React, { ReactNode, useEffect, useState } from 'react';
import _ from 'lodash'; // You need to install lodash using npm or yarn
import {
    Box,
    Flex,
    Image,
    Heading,
    Checkbox,
    Collapse,
} from "@chakra-ui/react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import { arrowDropDownSvg, imgDownload, imgSettings, imgSettingsGray } from 'assets';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAppApiData, fetchStaticSensorApiData, fetchWearableApiData, setCity, toggleSidebarCollapse } from 'reducer/reducer';
import { RootState } from 'store/store';
import { Data, defaultNewData } from 'reducer/state';
import cityWithLocation from '../data/lagos-accra_updated_loc.json';


export function Sidebar() {

    const [filterData, setFilterData] = useState<Data>(defaultNewData);
    const [blueSpace, setBlueSpace] = useState<boolean>(true);

    const isSidebarCollapse = useSelector((state: RootState) => state.app.isSidebarCollapse);
    const dispatch = useDispatch();
    const [categoryToggler, setCategoryToggler] = useState<Record<string, boolean>>({
        isCity: true,
        isMediaType: true,
        isDataSource: true,
        isAirQuality: true,
        isGoodEnviroment: true,
        isBadEnviroment: true,
        isDate: true,
        isEnvAirQuality: true,
        isEnvWalkability: true,
        isEnvHeat: true,
        isEnvAesthetics: true,
        isEnvGreenSpace: true,
        isEnvOther: true,
        isEnvLabel: true,
        isRisks: true,
        isBenefits: true
    });

    const toggleSidebarHandler = () => {
        dispatch(toggleSidebarCollapse());
    };


    const handleSelectAll = () => {
        setBlueSpace(true);
        const updatedFilterData: Data = _.cloneDeep(filterData);
        for (const category in updatedFilterData) {
            if (category !== 'date') {
                if (typeof updatedFilterData[category] === 'object' && !Array.isArray(updatedFilterData[category])) {
                    for (const field in updatedFilterData[category]) {
                        if (typeof updatedFilterData[category][field] === 'object' && !Array.isArray(updatedFilterData[category][field])) {
                            for (const subField in updatedFilterData[category][field]) {
                                updatedFilterData[category][field][subField] = true;
                            }
                        } else {
                            updatedFilterData[category][field] = true;
                        }
                    }
                } else {
                    updatedFilterData[category] = true;
                }
            }
        }
        setFilterData(updatedFilterData);
        dispatch(setCity(cityWithLocation));
    };

    const handleUnselectAll = () => {
        setBlueSpace(false);
        const updatedFilterData: Data = _.cloneDeep(filterData);
        for (const category in updatedFilterData) {
            if (category !== 'date') {
                if (typeof updatedFilterData[category] === 'object' && !Array.isArray(updatedFilterData[category])) {
                    for (const field in updatedFilterData[category]) {
                        if (typeof updatedFilterData[category][field] === 'object' && !Array.isArray(updatedFilterData[category][field])) {
                            for (const subField in updatedFilterData[category][field]) {
                                updatedFilterData[category][field][subField] = false;
                            }
                        } else {
                            updatedFilterData[category][field] = false;
                        }
                    }
                } else {
                    updatedFilterData[category] = false;
                }
            }
        }
        setFilterData(updatedFilterData);
        dispatch(setCity(null));
    };

    function selectAllCategory(category: keyof Data) {
        if (category === 'benefits') {
            setBlueSpace(true);
        }
        const updatedData: Data = _.cloneDeep(filterData);
        const categoryObject = updatedData[category];
        for (const key in categoryObject) {
            if (categoryObject.hasOwnProperty(key)) {
                updatedData[category][key] = true;
            }
        }
        setFilterData(updatedData);
    }

    function unSelectAllCategory(category: keyof Data) {
        if (category === 'benefits') {
            setBlueSpace(false);
        }
        const updatedData: Data = _.cloneDeep(filterData);
        const categoryObject = updatedData[category];
        for (const key in categoryObject) {
            if (categoryObject.hasOwnProperty(key)) {
                updatedData[category][key] = false;
            }
        }
        setFilterData(updatedData);
    }

    const selectAllEnvironmentKeys = (type: 'good' | 'bad') => {
        const updatedData: Data = _.cloneDeep(filterData);
        const environmentKeys = Object.keys(updatedData.environment);
        environmentKeys.forEach(category => {
            const categoryObject = updatedData.environment[category];
            for (const key in categoryObject) {
                if (categoryObject.hasOwnProperty(key) && key.startsWith(type)) {
                    updatedData.environment[category][key] = true;
                }
            }
        });
        setFilterData(updatedData);
    }

    const unSelectAllEnvironmentKeys = (type: 'good' | 'bad') => {
        const updatedData: Data = _.cloneDeep(filterData);
        const environmentKeys = Object.keys(updatedData.environment);
        environmentKeys.forEach(category => {
            const categoryObject = updatedData.environment[category];
            for (const key in categoryObject) {
                if (categoryObject.hasOwnProperty(key) && key.startsWith(type)) {
                    updatedData.environment[category][key] = false;
                }
            }
        });
        setFilterData(updatedData);
    }




    function filterDataByCity(city, data) {
        const filteredData = {};
        for (const deviceId in data) {
            if (data.hasOwnProperty(deviceId)) {
                if (data[deviceId].city.toLowerCase() === city.toLowerCase()) {
                    filteredData[deviceId] = data[deviceId];
                }
            }
        }
        return filteredData;
    }

    const fetchData = () => {
        if (filterData.city.accra && !filterData.city.lagos) {
            const filteredData = filterDataByCity('accra', cityWithLocation);
            dispatch(setCity(filteredData));
        } else if (filterData.city.lagos && !filterData.city.accra) {
            const filteredData = filterDataByCity('lagos', cityWithLocation);
            dispatch(setCity(filteredData));
        } else if (!filterData.city.lagos && !filterData.city.accra) {
            dispatch(setCity(null));
        } else if (filterData.city.lagos && filterData.city.accra) {
            dispatch(setCity(cityWithLocation));
        }

        if (filterData.dataSource.app) {
            const data = filterData;
            dispatch(fetchAppApiData(data) as any);
        } else {
            dispatch(fetchAppApiData({} as any) as any);
        }

        if (filterData.dataSource.wearable) {
            const data = filterData;
            dispatch(fetchWearableApiData(data) as any);
        } else {
            dispatch(fetchWearableApiData({} as any) as any);
        }

        if (filterData.dataSource.staticSensor) {
            const data = filterData;
            dispatch(fetchStaticSensorApiData(data) as any);
        } else {
            dispatch(fetchStaticSensorApiData({} as any) as any);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData on mount
    }, [filterData]); // Include filterData in dependencies to run effect when it changes

    useEffect(() => {
        fetchData(); // Call fetchData on mount
    }, []); // Include filterData in dependencies to run effect when it changes



    const handleCheckboxChanges = (event: React.ChangeEvent<HTMLInputElement> | any, category: keyof Data, subCategory?: string) => {
        const { name, value, checked } = event.target;
        console.log("data from event", name, value, checked);
        setFilterData((prevFilterData) => {
            if (subCategory) {
                // If subCategory is provided, update nested object
                return {
                    ...prevFilterData,
                    [category]: {
                        ...prevFilterData[category],
                        [subCategory]: {
                            ...prevFilterData[category][subCategory],
                            [name]: checked,
                        },
                    },
                };
            } else {
                // Update top-level object
                return {
                    ...prevFilterData,
                    [category]: {
                        ...prevFilterData[category],
                        [name]: name === "start" || name === "end" ? value : checked,
                    },
                };
            }
        });
    };

    function handleStartDateChange(date) {
        const event = {
            target: {
                name: "start",
                value: format(date, 'yyyy-MM-dd')
            }
        }
        handleCheckboxChanges(event, "date");
    }

    function handleEndDateChange(date) {
        const event = {
            target: {
                name: "end",
                value: format(date, 'yyyy-MM-dd')
            }
        }
        handleCheckboxChanges(event, "date");
    }
    console.log("filtered data", filterData)

    return (
        <div style={{ height: !isSidebarCollapse ? "59px" : "97vh", overflow: 'auto', borderRadius: "16px", scrollbarWidth: 'none' }}>
            <Flex gap="37px" flexDirection="column" alignItems="start">
                <Flex
                    bg="white.A700"
                    boxShadow="xs"
                    alignSelf="stretch"
                    flexDirection="column"
                    alignItems="start"
                    borderRadius="8px"
                >
                    <Flex
                        bg="gray.100"
                        alignSelf="stretch"
                        justifyContent="space-between"
                        alignItems="center"
                        gap="20px"
                        p="8px"
                        zIndex={1000}
                        borderRadius="8px"
                        onClick={toggleSidebarHandler}
                        style={{ position: 'fixed', width: '316px' }}
                    >
                        <Flex p="8px">
                            <Heading color="gray.900" my="3px">
                                Filter data
                            </Heading>
                        </Flex>
                        <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!isSidebarCollapse ? "rotate(180deg)" : ""} />
                    </Flex>
                    <Collapse in={isSidebarCollapse} >
                        <Flex
                            ml={{ md: "16px", base: "0px" }}
                            gap="8px"
                            w={{ md: "300px", base: "100%" }}
                            flexDirection="column"
                            alignItems="start"
                            p={{ md: "" }}
                            pt="64px"
                        >
                            <Flex gap="16px" flexWrap="wrap">
                                <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={handleSelectAll}>
                                    Select all
                                </Heading>
                                <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={handleUnselectAll}>
                                    Unselect all
                                </Heading>
                            </Flex>
                            <Flex flexDirection="row">
                                <Flex flexWrap="wrap" justifyContent="space-between" w="100%">
                                    <Flex justifyContent="space-between">
                                        <DatePicker
                                            selected={filterData.date.start ? parse(filterData.date.start, 'yyyy-MM-dd', new Date()) : null}
                                            onChange={handleStartDateChange}
                                            selectsStart
                                            startDate={filterData.date.start ? parse(filterData.date.start, 'yyyy-MM-dd', new Date()) : null}
                                            endDate={filterData.date.end ? parse(filterData.date.end, 'yyyy-MM-dd', new Date()) : new Date()}
                                            placeholderText="Enter date"
                                            maxDate={new Date()}  // This will disable future dates
                                        />
                                    </Flex>
                                    {/* <Flex flexDirection="row" justifyContent="space-between">
                                        <DatePicker
                                            selected={filterData.date.end ? parse(filterData.date.end, 'yyyy-MM-dd', new Date()) : new Date()}
                                            onChange={handleEndDateChange}
                                            selectsEnd
                                            startDate={filterData.date.start ? parse(filterData.date.start, 'yyyy-MM-dd', new Date()) : null}
                                            endDate={filterData.date.end ? parse(filterData.date.end, 'yyyy-MM-dd', new Date()) : new Date()}
                                            placeholderText="End Date"
                                        />
                                    </Flex> */}
                                </Flex>
                            </Flex>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isCity: !prevState.isCity
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        City
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isCity ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isCity}>
                                <Flex
                                    gap="8px"
                                    flexDirection={{ md: "column", base: "column", sm: "row" }}
                                >
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='lagos'
                                            isChecked={filterData.city.lagos}
                                            onChange={(e) => handleCheckboxChanges(e, "city")}
                                        />
                                        <Heading as="h6" mb="6px" alignSelf="end">Lagos</Heading>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='accra'
                                            isChecked={filterData.city.accra}
                                            onChange={(e) => handleCheckboxChanges(e, "city")}
                                        />
                                        <Heading as="h6" mb="6px" alignSelf="end">Accra</Heading>
                                    </Flex>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isDataSource: !prevState.isDataSource
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Data source
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isDataSource ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isDataSource}>
                                <Flex
                                    gap="8px"
                                    // w={{ md: "98%", base: "100%" }}
                                    flexDirection={{ md: "column", base: "column", sm: "row" }}
                                >
                                    <Flex gap="16px" flexWrap="wrap">
                                        <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllCategory("dataSource")}>
                                            Select all
                                        </Heading>
                                        <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllCategory("dataSource")}>
                                            Unselect all
                                        </Heading>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='app'
                                            isChecked={filterData.dataSource.app}
                                            onChange={(e) => handleCheckboxChanges(e, "dataSource")}
                                        >
                                        </Checkbox>
                                        <Image src={imgDownload} h="24px" w="24px" />
                                        <Heading as="h6" mb="6px" alignSelf="end">
                                            App
                                        </Heading>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='wearable'
                                            isChecked={filterData.dataSource.wearable}
                                            onChange={(e) => handleCheckboxChanges(e, "dataSource")}
                                        >
                                        </Checkbox>
                                        <Image src={imgSettings} h="24px" w="24px" />
                                        <Heading as="h6">Wearable</Heading>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='staticSensor'
                                            isChecked={filterData.dataSource.staticSensor}
                                            onChange={(e) => handleCheckboxChanges(e, "dataSource")}
                                        >
                                        </Checkbox>
                                        <Image src={imgSettingsGray} h="24px" w="24px" />
                                        <Heading as="h6">Static sensor</Heading>
                                    </Flex>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isAirQuality: !prevState.isAirQuality
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Air quality (PM2.5)
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isAirQuality ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isAirQuality} >
                                <Flex gap="8px" w={{ md: "100%", base: "100%", }} flexDirection="column">
                                    <Flex gap="16px" flexWrap="wrap">
                                        <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllCategory("airQuality")}>
                                            Select all
                                        </Heading>
                                        <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllCategory("airQuality")}>
                                            Unselect all
                                        </Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='good'
                                            isChecked={filterData.airQuality.good}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="green.400" w="24px" borderRadius="4px" />
                                        <Heading as="h6">Good</Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='moderate'
                                            isChecked={filterData.airQuality.moderate}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="yellow.300" w="24px" borderRadius="4px" />
                                        <Heading as="h6">Moderate</Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='unhealthyForSensitiveGroups'
                                            isChecked={filterData.airQuality.unhealthyForSensitiveGroups}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="red.300" w="24px" borderRadius="4px" />
                                        <Heading fontSize={"13px"}>Unhealthy for Sensitive Groups</Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='unhealthy'
                                            isChecked={filterData.airQuality.unhealthy}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="red.600" w="24px" borderRadius="4px" />
                                        <Heading as="h6">Unhealthy</Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='veryUnhealthy'
                                            isChecked={filterData.airQuality.veryUnhealthy}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="purple.600" w="24px" borderRadius="4px" />
                                        <Heading as="h6">Very Unhealthy</Heading>
                                    </Flex>
                                    <Flex gap="16px" flex={1} alignItems="center">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            h="32px"
                                            w="32px"
                                            fontWeight={700}
                                            alignSelf="stretch"
                                            py="7px"
                                            name='hazardous'
                                            isChecked={filterData.airQuality.hazardous}
                                            onChange={(e) => handleCheckboxChanges(e, "airQuality")}
                                        >
                                        </Checkbox>
                                        <Box h="24px" bg="pink.900" w="24px" borderRadius="4px" />
                                        <Heading as="h6">Hazardous</Heading>
                                    </Flex>
                                </Flex>
                            </Collapse>

                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isMediaType: !prevState.isMediaType
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Media type
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isMediaType ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isMediaType}>
                                <Flex gap="16px" flexWrap="wrap" flexDirection={{ md: "row", base: "row", sm: "row" }}>
                                    <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllCategory("mediaType")}>
                                        Select all
                                    </Heading>
                                    <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllCategory("mediaType")}>
                                        Unselect all
                                    </Heading>
                                </Flex>
                                <Flex
                                    gap="8px"
                                    w={{ md: "70%", base: "100%" }}
                                    flexDirection={{ md: "column", base: "column", sm: "row" }}
                                >

                                    <Checkbox
                                        value='true'
                                        checked={true}
                                        color="black.900"
                                        fontSize="14px"
                                        fontWeight={700}
                                        gap="16px"
                                        px="1px"
                                        py="7px"
                                        name='image'
                                        isChecked={filterData.mediaType.image}
                                        onChange={(e) => handleCheckboxChanges(e, "mediaType")}
                                    >
                                        Images
                                    </Checkbox>
                                    <Checkbox
                                        value="true"
                                        color="black.900"
                                        fontSize="14px"
                                        fontWeight={700}
                                        gap="16px"
                                        py="7px"
                                        name='text'
                                        isChecked={filterData.mediaType.text}
                                        onChange={(e) => handleCheckboxChanges(e, "mediaType")}
                                    >
                                        Text
                                    </Checkbox>
                                    <Checkbox
                                        value="true"
                                        color="black.900"
                                        fontSize="14px"
                                        fontWeight={700}
                                        gap="16px"
                                        py="7px"
                                        name='video'
                                        isChecked={filterData.mediaType.video}
                                        onChange={(e) => handleCheckboxChanges(e, "mediaType")}
                                    >
                                        Video
                                    </Checkbox>
                                    <Checkbox
                                        value="true"
                                        color="black.900"
                                        fontSize="14px"
                                        fontWeight={700}
                                        gap="16px"
                                        py="7px"
                                        name='audio'
                                        isChecked={filterData.mediaType.audio}
                                        onChange={(e) => handleCheckboxChanges(e, "mediaType")}
                                    >
                                        Audio
                                    </Checkbox>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isGoodEnviroment: !prevState.isGoodEnviroment
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Desirable environment
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isGoodEnviroment ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isGoodEnviroment}>
                                <Flex
                                    gap="8px"
                                    flexDirection={{ md: "column", base: "column", sm: "row" }}
                                >
                                    <Flex gap="16px" flexWrap="wrap">
                                        <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllEnvironmentKeys("good")}>
                                            Select all
                                        </Heading>
                                        <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllEnvironmentKeys("good")}>
                                            Unselect all
                                        </Heading>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodAirQuality'
                                            isChecked={filterData.environment.airQuality.goodAirQuality}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "airQuality")}
                                        >
                                            Air quality
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodWalkability'
                                            isChecked={filterData.environment.walkability.goodWalkability}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "walkability")}
                                        >
                                            Walkability
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodGreenSpace'
                                            isChecked={filterData.environment.greenspace.goodGreenSpace}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "greenspace")}
                                        >
                                            Green space
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodAesthetics'
                                            isChecked={filterData.environment.aesthetics.goodAesthetics}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "aesthetics")}
                                        >
                                            Aesthetics
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodHeat'
                                            isChecked={filterData.environment.heat.goodHeat}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "heat")}
                                        >
                                            Heat
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='goodOther'
                                            isChecked={filterData.environment.other.goodOther}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "other")}
                                        >
                                            Other
                                        </Checkbox>
                                    </Flex>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isBadEnviroment: !prevState.isBadEnviroment
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Undesirable environment
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isBadEnviroment ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isBadEnviroment}>
                                <Flex gap="16px" flexWrap="wrap">
                                    <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllEnvironmentKeys("bad")}>
                                        Select all
                                    </Heading>
                                    <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllEnvironmentKeys("bad")}>
                                        Unselect all
                                    </Heading>
                                </Flex>
                                <Flex
                                    gap="8px"
                                    flexDirection={{ md: "column", base: "column", sm: "row" }}
                                >
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badAirQuality'
                                            isChecked={filterData.environment.airQuality.badAirQuality}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "airQuality")}
                                        >
                                            Air quality
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badWalkability'
                                            isChecked={filterData.environment.walkability.badWalkability}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "walkability")}
                                        >
                                            Walkability
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badGreenSpace'
                                            isChecked={filterData.environment.greenspace.badGreenSpace}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "greenspace")}
                                        >
                                            Green space
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badAesthetics'
                                            isChecked={filterData.environment.aesthetics.badAesthetics}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "aesthetics")}
                                        >
                                            Aesthetics
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badHeat'
                                            isChecked={filterData.environment.heat.badHeat}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "heat")}
                                        >
                                            Heat
                                        </Checkbox>
                                    </Flex>
                                    <Flex gap="16px" alignItems="center" flexWrap="wrap">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            py="7px"
                                            name='badOther'
                                            isChecked={filterData.environment.other.badOther}
                                            onChange={(e) => handleCheckboxChanges(e, "environment", "other")}
                                        >
                                            Other
                                        </Checkbox>
                                    </Flex>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isRisks: !prevState.isRisks
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Risks
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isRisks ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isRisks} >
                                <Flex flexDirection="column">
                                    <Flex gap="16px" flexWrap="wrap">
                                        <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllCategory("risks")}>
                                            Select all
                                        </Heading>
                                        <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllCategory("risks")}>
                                            Unselect all
                                        </Heading>
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskBurning'
                                            isChecked={filterData.risks.riskBurning}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Burning
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskDust'
                                            isChecked={filterData.risks.riskDust}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Dust
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskInjury'
                                            isChecked={filterData.risks.riskInjury}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Injury
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskNoise'
                                            isChecked={filterData.risks.riskNoise}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Noise
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskObstructions'
                                            isChecked={filterData.risks.riskObstructions}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Obstructions
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskOther'
                                            isChecked={filterData.risks.riskOther}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Other
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskRestrictedAccess'
                                            isChecked={filterData.risks.riskRestrictedAccess}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Restricted access
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskSafetySecurity'
                                            isChecked={filterData.risks.riskSafetySecurity}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Safety security
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskSmell'
                                            isChecked={filterData.risks.riskSmell}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Smell
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='riskTraffic'
                                            isChecked={filterData.risks.riskTraffic}
                                            onChange={(e) => handleCheckboxChanges(e, "risks")}
                                        >
                                            Traffic
                                        </Checkbox>
                                    </Flex>
                                </Flex>
                            </Collapse>
                            <Flex
                                bg="gray.100"
                                alignSelf="stretch"
                                justifyContent="space-between"
                                alignItems="center"
                                gap="20px"
                                p="8px"
                                borderRadius="8px"
                                onClick={() => setCategoryToggler(prevState => ({
                                    ...prevState,
                                    isBenefits: !prevState.isBenefits
                                }))}
                                style={{ position: 'relative', width: '300px' }}
                            >
                                <Flex p="8px">
                                    <Heading color="gray.900" my="3px">
                                        Benefits
                                    </Heading>
                                </Flex>
                                <Image src={arrowDropDownSvg} h="24px" w="24px" transform={!categoryToggler.isBenefits ? "rotate(180deg)" : ""} />
                            </Flex>
                            <Collapse in={categoryToggler.isBenefits}>
                                <Flex flexDirection="column">
                                    <Flex gap="16px" flexWrap="wrap">
                                        <Heading cursor='pointer' as="h3" color="light_blue.400" onClick={() => selectAllCategory("benefits")}>
                                            Select all
                                        </Heading>
                                        <Heading cursor='pointer' as="h4" color="light_blue.400" onClick={() => unSelectAllCategory("benefits")}>
                                            Unselect all
                                        </Heading>
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitBarrierCarDedicatedPath'
                                            isChecked={filterData.benefits.benefitBarrierCarDedicatedPath}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Dedicated path
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitInclusive'
                                            isChecked={filterData.benefits.benefitInclusive}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Inclusive
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitLighting'
                                            isChecked={filterData.benefits.benefitLighting}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Lighting
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitNiceView'
                                            isChecked={filterData.benefits.benefitNiceView}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Nice view
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitBlueSpace'
                                            // isChecked={filterData.benefits.benefitOther}
                                            isChecked={blueSpace}
                                            // onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                            onChange={() => setBlueSpace(!blueSpace)}
                                        >
                                            Blue Space
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitTrees'
                                            isChecked={filterData.benefits.benefitTrees}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Trees
                                        </Checkbox>
                                        <Checkbox
                                            value="true"
                                            color="black.900"
                                            fontSize="14px"
                                            fontWeight={700}
                                            gap="16px"
                                            px="1px"
                                            py="7px"
                                            name='benefitOther'
                                            isChecked={filterData.benefits.benefitOther}
                                            onChange={(e) => handleCheckboxChanges(e, "benefits")}
                                        >
                                            Other
                                        </Checkbox>
                                    </Flex>
                                </Flex>
                            </Collapse>
                        </Flex>
                    </Collapse>
                </Flex>
            </Flex>
        </div>
    )
}