import React, { useEffect, useRef, useState } from "react";
import { Box, Text, Flex, Heading, Image, TabList, Tab, Tabs, TabPanels, TabPanel } from "@chakra-ui/react";
import { appGood, appModerate, appUnhealthy, appUnhealthyforsensitivegroups, goodMessage, imgUser, neutralMessage, seriousIssue, warning } from "assets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AirQuality } from "reducer/state";
import { Slider } from "./Slider";
import { EventObject } from "react-alice-carousel";


// Air quality category and color mappings
const aqiCategory = ['Moderate', 'Good', 'Unhealthy for Sensitive Groups', 'Unhealthy', 'Very Unhealthy', 'Hazardous'];
const aqiColor = ['#ffff00', '#00e400', '#ff7e00', '#ff0000', '#8639c0', '#81202e'];

const isImageUrl = (url) => {
  return /^https?:\/\/.*\.(png|jpg|jpeg|gif|bmp|webp)(\?.*)?$/.test(url);
};

const isVideoUrl = (url) => {
  return /^https?:\/\/.*\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv|m4v|3gp|mpeg|mpg)(\?.*)?$/i.test(url);
};

type NonAirType = "Serious issue" | "Warning" | "Good message" | "Neutral message"

export default function Visualization({ data, handleClose }): React.JSX.Element {

  const [
    id,
    user,
    device,
    text,
    photos,
    videos,
    audio,
    category,
    latitude,
    longitude,
    createdAt,
    riskObstructions,
    riskInjury,
    riskSafetySecurity,
    riskRestrictedAccess,
    riskNoise,
    riskDust,
    riskTraffic,
    riskBurning,
    riskSmell,
    riskOther,
    benefitLighting,
    benefitBarrierCarDedicatedPath,
    benefitInclusive,
    benefitTrees,
    benefitNiceView,
    benefitOther,
    badAirQuality,
    badWalkability,
    badHeat,
    badAesthetics,
    badGreenSpace,
    badOther,
    goodAirQuality,
    goodWalkability,
    goodHeat,
    goodAesthetics,
    goodGreenSpace,
    goodOther,
    boolNotApplicable,
    boolStartRun,
    boolEndRun,
    boolGood,
    boolBad,
    city
  ] = data;


  const [nonAirRelated, setNonAirRelated] = useState<NonAirType[]>([]);

  useEffect(() => {
    const states: NonAirType[] = [];

    if (
      riskObstructions || riskInjury || riskSafetySecurity || riskRestrictedAccess ||
      riskNoise || riskDust || riskTraffic || riskBurning || riskSmell || riskOther
    ) {
      states.push("Warning");
    }

    if (
      badAesthetics || badAirQuality || badWalkability || badHeat ||
      badGreenSpace || badOther || boolBad
    ) {
      states.push("Serious issue");
    }

    if (
      goodAesthetics || goodAirQuality || goodGreenSpace || goodHeat ||
      goodWalkability || goodOther || benefitLighting || benefitBarrierCarDedicatedPath ||
      benefitInclusive || benefitTrees || benefitNiceView || benefitOther || boolGood
    ) {
      states.push("Good message");
    }

    if (boolNotApplicable || boolStartRun || boolEndRun) {
      states.push("Neutral message");
    }

    setNonAirRelated(states);
  }, [
    riskObstructions, riskInjury, riskSafetySecurity, riskRestrictedAccess,
    riskNoise, riskDust, riskTraffic, riskBurning, riskSmell, riskOther,
    badAesthetics, badAirQuality, badWalkability, badHeat,
    badGreenSpace, badOther,
    goodAesthetics, goodAirQuality, goodGreenSpace, goodHeat,
    goodWalkability, goodOther, benefitLighting, benefitBarrierCarDedicatedPath,
    benefitInclusive, benefitTrees, benefitNiceView, benefitOther,
    boolNotApplicable, boolStartRun, boolEndRun, boolBad, boolGood
  ]);

  const [value, setValue] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(contentRef.current.scrollHeight > 100);
    }
  }, [text]);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const backgroundColorMap: Record<NonAirType, string> = {
    "Serious issue": "#D41414",
    "Warning": "#F36317",
    "Good message": "#248F2F",
    "Neutral message": "#25A9E0"
  };

  const iconMap: Record<NonAirType, any> = {
    "Serious issue": seriousIssue,
    "Warning": warning,
    "Good message": goodMessage,
    "Neutral message": neutralMessage
  };

  const goodBackgroundColor = backgroundColorMap["Good message"];
  const badBackgroundColor = backgroundColorMap["Serious issue"];
  const warningBackgroundColor = backgroundColorMap["Warning"];
  const neutralBackgroundColor = backgroundColorMap["Neutral message"];
  const airQuality = AirQuality[category];

  const airQualityToCategory = {
    "good": "Good",
    "moderate": "Moderate",
    "unhealthyForSensitiveGroups": "Unhealthy for Sensitive Groups",
    "unhealthy": "Unhealthy",
  };
  const airQualityCategory = airQualityToCategory[airQuality];
  const backgroundColorCat = aqiColor[aqiCategory.indexOf(airQualityCategory)];

  // Determine icon based on air quality category
  const iconMapCat = {
    "Moderate": appModerate,
    "Good": appGood,
    "Unhealthy for Sensitive Groups": appUnhealthyforsensitivegroups,
    "Unhealthy": appUnhealthy,
  };

  const iconCat = iconMapCat[airQualityCategory];

  const [sliderState4, setSliderState4] = useState(0);
  const sliderRef4 = useRef(null);

  console.log("slider", sliderState4)

  // Normalize data to always be arrays
  const splitAndFilterUrls = (urls) => {
    if (!urls) return [];
    return urls.replace(/[{}]/g, '').split(',').filter(Boolean);
  };

  // Split and filter the URLs for photos, videos, and audio
  const photosUrls = Array.isArray(cleanUrl(photos))
    ? photos.flatMap(splitAndFilterUrls)
    : splitAndFilterUrls(cleanUrl(photos));

  const videosUrls = Array.isArray(cleanUrl(videos))
    ? videos.flatMap(splitAndFilterUrls)
    : splitAndFilterUrls(cleanUrl(videos));

  const audioUrls = Array.isArray(cleanUrl(audio))
    ? audio.flatMap(splitAndFilterUrls)
    : splitAndFilterUrls(cleanUrl(audio));
  console.log("photosUrls", photosUrls)
  console.log("videosUrls", videosUrls)
  console.log("audioUrls", audioUrls)

  const mediaItems = [...photosUrls, ...videosUrls].filter(Boolean); // Remove any null or undefined values
  const medias = [...photosUrls, ...videosUrls, ...audioUrls].filter(Boolean); // Remove any null or undefined values


  console.log("image", photos)
  console.log("video", videos)
  console.log("audio", audio)
  console.log("text", cleanUrl(photosUrls[0]))
  console.log("see if imageUrl matches pattern", isImageUrl(photosUrls[0]))
  console.log("see if videoUrl matches pattern", isVideoUrl(mediaItems[0]))
  console.log("mediaItems", mediaItems)
  console.log("iconCat", iconCat)

  const handleNext = () => {
    if (sliderRef4.current) {
      (sliderRef4.current as unknown as { slideNext(): void }).slideNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef4.current) {
      (sliderRef4.current as unknown as { slidePrev(): void }).slidePrev();
    }
  };


  return (
    <div style={{ scrollbarWidth: 'none', height: '98vh', overflow: 'auto', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)', borderRadius: "16px", background: "white" }}>
      <Flex
        // pb={{ base: "20px", sm: "21px" }}
        gap="16px"
        bg="white.A700"
        boxShadow="xs"
        w={{ base: "316px", sm: "316px" }}
        flexDirection="column"
        alignItems="center"
        alignSelf="center"
        borderRadius="16px"
        h="98vh"
      >
        <Box style={{ position: 'fixed', height: mediaItems.length > 0 ? "225px" : "55px", borderRadius: "16px", width: "300px", zIndex: 1000, overflow: 'hidden', background: "#fff" }}>
          <div onClick={handleClose} style={{ position: 'absolute', top: '10px', right: '5px', background: '#25a9e0', width: '25px', height: '25px', alignContent: 'center', zIndex: 1000 }}>
            <FontAwesomeIcon onClick={handleClose} style={{ paddingLeft: '3px', zIndex: 1000 }} color="white" size="xl" icon={faClose} />
          </div>
          <div style={{ position: "relative", display: mediaItems.length > 0 ? "block" : "none" }}>
            <Slider
              responsive={{ "0": { items: 1 }, "550": { items: 1 }, "1050": { items: 1 } }}
              disableDotsControls
              activeIndex={sliderState4}
              onSlideChanged={(e: EventObject) => {
                setSliderState4(e?.item);
              }}
              ref={sliderRef4}
              alignSelf="stretch"
              items={mediaItems.map((url, index) => (
                <React.Fragment key={index}>
                  {isImageUrl(url) ? (
                    <img src={cleanUrl(url)} alt={`media-${index}`} style={{ height: '216px', margin: 'auto', borderTopLeftRadius: "16px", borderTopRightRadius: "16px", }} />
                  ) : isVideoUrl(url) ? (
                    <video src={cleanUrl(url)} height="216px" controls style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px", width: "100%" }} />
                  ) : null}
                </React.Fragment>
              ))}
            />
            <div className="prev-button" style={{ display: mediaItems.length > 1 ? "block" : "none" }} onClick={handlePrev}>
              <FontAwesomeIcon style={{ zIndex: 1000 }} color="white" size="xl" icon={faArrowLeft} />
            </div>
            <div className="next-button" style={{ display: mediaItems.length > 1 ? "block" : "none" }} onClick={handleNext}>
              <FontAwesomeIcon style={{ zIndex: 1000 }} color="white" size="xl" icon={faArrowRight} />
            </div>
          </div>
        </Box>
        <Tabs
          // gap="37px"
          bg="white.A700"
          display="flex"
          flexWrap="wrap"
          height="100%"
          zIndex={0}
          w="100%"
          top={mediaItems.length > 0 ? "225px" : "55px"}
          px="10px"
          justifyContent="space-between"
          index={value}
          onChange={(index) => handleChange(index)}
        >
          <TabList
            bg="white.A700"
            boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
            zIndex={100}
            display="flex"
            flexWrap="nowrap"
            position="fixed"
            width="300px"
            p="5px"
            // h="55px"
            borderRadius="56px"
            justifyContent="space-around"
          >
            <Tab
              color="gray.400"
              fontSize="12px"
              fontWeight={700}
              alignSelf="start"
              px="16px"
              py="8px"
            >
              Suggestions
            </Tab>
            <Tab
              color="gray.400"
              fontSize="12px"
              fontWeight={700}
              alignSelf="start"
              px="16px"
              py="8px"
            >
              Media
            </Tab>
          </TabList>
          <TabPanels top="50px" position="relative" style={{ padding: "0px !important", zIndex: 0 }}>
            <TabPanel position="relative" style={{ zIndex: 1 }}>
              <Flex
                gap="15px"
                w={{ md: "100 %", base: "100%" }}
                flexDirection="column"
                alignItems="start"
              >
                {nonAirRelated.length > 0 ?
                  <Flex gap="12px" alignSelf="stretch" flexDirection="column">
                    <Flex gap="8px" flexDirection={{ md: "column", base: "row" }}>
                      {category &&
                        <Flex
                          gap="8px"
                          bg="white.A700"
                          boxShadow="lg"
                          flex={1}
                          alignItems="center"
                          p="8px"
                          borderRadius="8px"
                        >
                          <Box h="39px" bg={backgroundColorCat} w="3px" borderRadius="1px" />
                          {iconCat && <Image src={iconCat} h="30px" w="30px" />}
                          <Heading as="h5" mb="9px" alignSelf="end">
                            {airQuality}
                          </Heading>
                        </Flex>
                      }
                      <Flex gap="8px" flexDirection={{ md: "column", base: "row" }}>
                        {nonAirRelated.map((type, index) => (
                          <Flex
                            key={index}
                            gap="8px"
                            bg="white.A700"
                            boxShadow="lg"
                            flex={1}
                            alignItems="center"
                            p="8px"
                            borderRadius="8px"
                          >
                            <Box h="39px" bg={backgroundColorMap[type]} w="3px" borderRadius="1px" />
                            <Image src={iconMap[type]} h="29px" w="29px" />
                            <Heading as="h6" mb="9px" alignSelf="end">
                              {type}
                            </Heading>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                    {text && text !== "NaN" &&
                      <Box paddingTop="10px">
                        <div
                          ref={contentRef}
                          style={{
                            maxHeight: isExpanded ? 'none' : "100px",
                            overflow: isExpanded ? "visible" : "hidden",
                            transition: "max-height 0.3s ease",
                          }}
                        >
                          <Text>
                            <>
                              {text}
                              <br />
                            </>
                          </Text>
                        </div>
                        {isOverflowing && (
                          <Text size="s" as="span" color="light_blue.400" fontWeight={700} variant="link" colorScheme="blue" onClick={toggleExpand}>
                            {isExpanded ? 'Less ...' : 'More ...'}
                          </Text>
                        )}
                      </Box>
                    }
                    <Box className="media">
                      {(riskObstructions || riskInjury || riskSafetySecurity || riskRestrictedAccess || riskNoise || riskDust || riskTraffic || riskBurning || riskSmell || riskOther) &&
                        <Flex gap="17px" flexDirection="column" alignItems="start" marginBottom="15px">
                          <Flex>{(riskObstructions || riskInjury || riskSafetySecurity || riskRestrictedAccess || riskNoise || riskDust || riskTraffic || riskBurning || riskSmell || riskOther) && <h5>Risks</h5>}</Flex>
                          <Flex pl="5px" gap="5px" flexDirection="column">
                            {riskObstructions &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Obstructions
                              </Flex>
                            }
                            {riskInjury &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Injury
                              </Flex>
                            }
                            {riskSafetySecurity &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Safety security
                              </Flex>
                            }
                            {riskRestrictedAccess &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Ristricted access
                              </Flex>
                            }
                            {riskNoise &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Noise
                              </Flex>
                            }
                            {riskDust &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Dust
                              </Flex>
                            }
                            {riskTraffic &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Trafic
                              </Flex>
                            }
                            {riskBurning &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Burning
                              </Flex>
                            }
                            {riskSmell &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Smell
                              </Flex>
                            }
                            {riskOther &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={warningBackgroundColor} w="3px" borderRadius="1px" />
                                Other
                              </Flex>
                            }
                          </Flex>
                        </Flex>
                      }
                      {(benefitLighting || benefitBarrierCarDedicatedPath || benefitInclusive || benefitTrees || benefitNiceView || benefitOther) &&
                        <Flex gap="17px" flexDirection="column" alignItems="start" marginBottom="15px">
                          <Flex>{(benefitLighting || benefitBarrierCarDedicatedPath || benefitInclusive || benefitTrees || benefitNiceView || benefitOther) && <h5>Benefits</h5>}</Flex>
                          <Flex pl="5px" gap="5px" flexDirection="column">
                            {benefitLighting &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Lighting
                              </Flex>
                            }
                            {benefitBarrierCarDedicatedPath &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Barrier Car Dedicated Path
                              </Flex>
                            }
                            {benefitInclusive &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Inclusive
                              </Flex>
                            }
                            {benefitTrees &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Trees
                              </Flex>
                            }
                            {benefitNiceView &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Nice view
                              </Flex>
                            }
                            {benefitOther &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Other
                              </Flex>
                            }
                          </Flex>
                        </Flex>
                      }
                      {(badAesthetics || badAirQuality || badWalkability || badHeat || badGreenSpace || badOther || boolBad) &&
                        <Flex gap="17px" flexDirection="column" alignItems="start" marginBottom="15px">
                          <Flex>{(badAesthetics || badAirQuality || badWalkability || badHeat || badGreenSpace || badOther || boolBad) && <h5>Bad Conditions</h5>}</Flex>
                          <Flex pl="5px" gap="5px" flexDirection="column">
                            {badAirQuality &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Air quality
                              </Flex>
                            }
                            {badWalkability &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Walkability
                              </Flex>
                            }
                            {badHeat &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Heat
                              </Flex>
                            }
                            {badAesthetics &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Aesthetics
                              </Flex>
                            }
                            {badGreenSpace &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Green space
                              </Flex>
                            }
                            {badOther &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Other
                              </Flex>
                            }
                            {boolBad &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={badBackgroundColor} w="3px" borderRadius="1px" />
                                Bad
                              </Flex>
                            }
                          </Flex>
                        </Flex>
                      }
                      {(goodAesthetics || goodAirQuality || goodGreenSpace || goodHeat || goodHeat || goodWalkability || goodOther || boolGood) &&
                        <Flex gap="17px" flexDirection="column" alignItems="start" marginBottom="15px">
                          <Flex>{(goodAesthetics || goodAirQuality || goodGreenSpace || goodHeat || goodHeat || goodWalkability || goodOther || boolGood) && <h5>Good Conditions</h5>}</Flex>
                          <Flex pl="5px" gap="5px" flexDirection="column">
                            {goodAirQuality &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Air quality
                              </Flex>
                            }
                            {goodWalkability &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Walkability
                              </Flex>
                            }
                            {goodHeat &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Heat
                              </Flex>
                            }
                            {goodAesthetics &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Aesthetics
                              </Flex>
                            }
                            {goodGreenSpace &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Green space
                              </Flex>
                            }
                            {goodOther &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Other
                              </Flex>
                            }
                            {boolGood &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={goodBackgroundColor} w="3px" borderRadius="1px" />
                                Good
                              </Flex>
                            }
                          </Flex>
                        </Flex>
                      }
                      {(boolEndRun || boolNotApplicable || boolStartRun) &&
                        <Flex gap="17px" flexDirection="column" alignItems="start" marginBottom="15px">
                          <Flex>{(boolEndRun || boolNotApplicable || boolStartRun) && <h5>Neutral</h5>}</Flex>
                          <Flex pl="5px" gap="5px" flexDirection="column">
                            {boolNotApplicable &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={neutralBackgroundColor} w="3px" borderRadius="1px" />
                                Not Applicable
                              </Flex>
                            }
                            {boolEndRun &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={neutralBackgroundColor} w="3px" borderRadius="1px" />
                                End Run
                              </Flex>
                            }
                            {boolStartRun &&
                              <Flex
                                gap="8px"
                                bg="white.A700"
                                boxShadow="lg"
                                flex={1}
                                alignItems="center"
                                p="8px"
                                borderRadius="8px"
                              >
                                <Box h="39px" bg={neutralBackgroundColor} w="3px" borderRadius="1px" />
                                Start Run
                              </Flex>
                            }
                          </Flex>
                        </Flex>
                      }
                    </Box>
                    <Flex gap="12px" flexDirection="column" alignItems="start">
                      <Box h="1px" bg="gray.100" alignSelf="stretch" w="100%" />
                      <Text>{createdAt}</Text>
                    </Flex>
                  </Flex>
                  : <Heading as="h2">No extra information</Heading>
                }
              </Flex>
            </TabPanel>
            <TabPanel position="relative" style={{ zIndex: 1 }}>
              <Box w={{ md: "auto", base: "100%" }} >
                <Flex gap="17px" flexDirection="column" alignItems="start">
                  {medias.length > 0 ?
                    <>
                      {photosUrls.length > 0 &&
                        <>
                          <Heading>Images</Heading>
                          <Flex gap="8px" alignSelf="stretch" flexDirection="row">
                            {mediaItems.map((url, index) => (
                              <React.Fragment key={index}>
                                {isImageUrl(url) ? (
                                  <Image src={cleanUrl(url)} onClick={() => setSliderState4(index)} height="76px" width="31%" borderRadius="8px" />
                                ) : null}
                              </React.Fragment>
                            ))}
                          </Flex>
                        </>
                      }
                      {videosUrls.length > 0 &&
                        <>
                          <Heading as="h3">Videos</Heading>
                          <Flex gap="8px" alignSelf="stretch" flexDirection="row">
                            {mediaItems.map((url, index) => (
                              <React.Fragment key={index}>
                                {isVideoUrl(url) ? (
                                  <video src={cleanUrl(url)} onClick={() => setSliderState4(index)} controls style={{ height: "76px", width: "31%", borderRadius: "8px" }} />
                                ) : null}
                              </React.Fragment>
                            ))}
                          </Flex>
                        </>
                      }
                      {audioUrls.length > 0 &&
                        <>
                          <Heading as="h4">Audio</Heading>
                          <Flex gap="8px" alignSelf="stretch" flexDirection="column" alignItems="start">
                            {audioUrls.map((url) =>
                              <audio controls style={{ width: "265px" }}><source src={cleanUrl(url)} type="audio/wav" /></audio>
                            )}
                          </Flex>
                        </>
                      }
                    </>
                    : <Heading as="h2">No media</Heading>
                  }
                  <Flex gap="12px" alignSelf="stretch" flexDirection="column" alignItems="start">
                    <Box h="1px" bg="gray.100" alignSelf="stretch" w="100%" />
                    <Text>{createdAt}</Text>
                  </Flex>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </div>
  );
}

const cleanUrl = (url) => {
  if (typeof url === 'string') {
    return url.replace(/^{|}$/g, '');
  }
}