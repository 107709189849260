import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store/store'
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";


const root = createRoot(document.getElementById('root'));

root.render(
  <ChakraProvider theme={theme}>
  <Provider store={store}>
    <App />
  </Provider>
  </ChakraProvider>
);
