import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Data, InitialState, defaultNewData } from './state';
import { format } from 'date-fns';



const BASE_URL = "https://uqtks33abqcvrl5op5rfhyfhbm0zbefp.lambda-url.us-east-1.on.aws/retrieve_data/"
const APP_API_URL = "app_sensors";
const WEARABLE_API_URL = "wearable_sensors";
const STATIC_SENSOR_API_URL = "static_sensors";

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const initialState: InitialState = {
  isOpen: true,
  isSidebarCollapse: true,
  isLoading: false,
  error: null,
  appEntity: null,
  wearableEntity: null,
  staticEntity: null,
  city: null,
  filteredData: defaultNewData
};


function ConstantDateRange() {
  function getTodayDate() {
    return new Date();
  }

  function getYesterdayDate() {
    const today = getTodayDate();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return yesterday;
  }

  const startDate = getYesterdayDate();
  const endDate = getTodayDate();

  return {
    start: startDate,
    end: endDate,
    update: function () {
      return {
        start: getYesterdayDate(),
        end: getTodayDate()
      };
    }
  };
}

const dateData = ConstantDateRange();

function getDateFromDayPlusOne(date) {
  const nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
}

// Thunk action creator for fetching data from an API
export const fetchAppApiData = createAsyncThunk('app/app_sensors', async (requestData: Data) => {
  console.log("Fetch App Sensor is called!");

  const { dataSource, airQuality, ...rest } = requestData;
  const data = { ...rest };  // Create a shallow copy of the rest object

  if (!data.date || data.date.start === "") {
    console.error("Start date is empty or undefined");
    return;
  }

  console.log("data sent", requestData);
  console.log("data received", data);

  try {
    const endDate = getDateFromDayPlusOne(data.date.start);
    // Create a new date object with the end date
    data.date = { ...data.date, end: format(endDate, 'yyyy-MM-dd') };
    console.log("data with end date", data);

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow"
    };

    const response = await fetch(BASE_URL + APP_API_URL, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    console.log("response data", responseData);
    return responseData;
  } catch (error) {
    console.error("Error while processing data:", error);
    throw error;
  }
});

export const fetchWearableApiData = createAsyncThunk('app/wearable_sensors', async (requestData: Data) => {
  console.log("Fetch Wearable Sensor is called!");
  const { date, airQuality, city } = requestData;

  const data = {
    date: { ...date },  // Create a shallow copy of the date object
    airQuality: airQuality,
    city: city,
  };

  if (!data.date || data.date.start === "") {
    console.error("Start date is empty or undefined");
    return;
  }

  try {
    const endDate = getDateFromDayPlusOne(data.date.start);
    data.date.end = format(endDate, 'yyyy-MM-dd');
    console.log("data with end date", data);

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow"
    };

    const response = await fetch(BASE_URL + WEARABLE_API_URL, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    console.log("response data", responseData);
    return responseData;
  } catch (error) {
    console.error("Error while processing data:", error);
    throw error;
  }
});

export const fetchStaticSensorApiData = createAsyncThunk('app/static_sensors', async (requestData: Data) => {
  console.log("Fetch Static Sensor is called!");
  const { date, airQuality, city } = requestData;

  const data = {
    date: { ...date },  // Create a shallow copy of the date object
    airQuality: airQuality,
    city: city,
  };

  if (!data.date || data.date.start === "") {
    console.error("Start date is empty or undefined");
    return;
  }

  try {
    const endDate = getDateFromDayPlusOne(data.date.start);
    data.date.end = format(endDate, 'yyyy-MM-dd');
    console.log("data with end date", data);

    const requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow"
    };

    const response = await fetch(BASE_URL + STATIC_SENSOR_API_URL, requestOptions);

    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();
    console.log("response data", responseData);
    return responseData;
  } catch (error) {
    console.error("Error while processing data:", error);
    throw error;
  }
});

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebar: state => {
      state.isOpen = !state.isOpen;
    },
    toggleSidebarCollapse: state => {
      state.isSidebarCollapse = !state.isSidebarCollapse;
    },
    setCity: (state, payload) => {
      state.city = payload.payload;
    },
    setisOpen: (state, payload) => {
      state.isOpen = payload.payload;
    },
    setFilteredData: (state, payload) => {
      state.filteredData = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppApiData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAppApiData.fulfilled, (state, action) => {
        // Process the data from the API response
        state.appEntity = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAppApiData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.appEntity = null;
      })
      .addCase(fetchStaticSensorApiData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStaticSensorApiData.fulfilled, (state, action) => {
        // Process the data from the API response
        state.staticEntity = action.payload
        state.isLoading = false;
      })
      .addCase(fetchStaticSensorApiData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.staticEntity = null;
      })
      .addCase(fetchWearableApiData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWearableApiData.fulfilled, (state, action) => {
        // Process the data from the API response
        state.wearableEntity = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchWearableApiData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.wearableEntity = null;
      });
  },
});

export const { toggleSidebar, toggleSidebarCollapse, setCity, setisOpen, setFilteredData } = appSlice.actions;

export default appSlice.reducer;
